import React from 'react'
import './Reviews.css'
import Undi from '../../Images/underline.png'

function Reviews() {
  return (
    <>
      <div className="headerBar">
        <h1>Reviews</h1>
      </div>
      <div className="reviewsContainer">
        <h5>Reviews</h5>
        <h1>Our Clients Say</h1>
        <img src={Undi} alt="" />
        <div className="review">
          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Varsha Singh</h2>
            <p>"Tawa’s paneer tikka is out of this world! Highly recommend for anyone who loves authentic Indian cuisine."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Minal Patil</h2>
            <p>"Tawa never disappoints! The food is consistently delicious, and the service is always friendly and efficient."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Vaishali Shinde</h2>
            <p>"A true gem in Panvel and Kharghar. Authentic flavors, cozy ambiance, and excellent value for money."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Shravani Gharat</h2>
            <p>"Every visit to Tawa is a delightful culinary journey. The butter chicken is an absolute must-try!"
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Swati Dube</h2>
            <p>"Tawa’s tandoori dishes are simply the best. Perfectly cooked and seasoned to perfection, every time."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Smriti Gaikwad</h2>
            <p>"Love the variety on the menu. There's something for everyone, and it’s all incredibly tasty."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Himali Chavan</h2>
            <p>"The ambiance at Tawa is warm and inviting. Perfect spot for a family dinner or casual meet-up."
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Teju Chavan</h2>
            <p>"I’ve been a regular at Tawa for years. The consistency in taste and quality is unmatched."
            </p>
          </div>

        </div>
      </div>
    </>
  )
}

export default Reviews
