import React from 'react';
import Logo from '../../Images/tawaLogo.png';
import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <>
      <div className="footerTopContainer">
        <div className="footerLogoInfo">
          <img src={Logo} alt="" />
          <p>Tawa is a culinary landmark, offering an exquisite blend of traditional and contemporary Indian flavors in every dish.</p>
          <div className="footerLogoIcons">
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-youtube"></i>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <div className="footerBox1">

          {/* <img src={Logo} alt="" />
          <div className="footerIcons">
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-youtube"></i>
          </div> */}
          <h2>About Us</h2>
          <p>With branches in Panvel, Kharghar, and other locations, Tawa is where food lovers gather to indulge in a dining experience that is both rich in taste and warm in hospitality.</p>
          <div className="footerIcon1">
            <i class="fa-solid fa-phone"></i>
            <h5>+91 76667 64011</h5>
          </div>
          <div className="footerIcon2">
            <i class="fa-solid fa-envelope"></i>
            <h5>info@tawa.com</h5>
          </div>
          <div className="footerIcon2">
            <i class="fa-solid fa-location-dot"></i>
            <h5>No 37 & 38, Hariom Heritage, opposite Kesar Harmony, Sector 21, Kharghar, Navi Mumbai, Maharashtra 410210</h5>
          </div>
        </div>
        <div className="footerBox3">
          <h3>Quick Links</h3>
          <ul >
            <li><NavLink to="/" className={() => `navlink`}>Home</NavLink></li>
            <li><NavLink to="/about" className={() => `navlink`}>About Us</NavLink></li>
            <li><NavLink to="/menu" className={() => `navlink`}>Menu</NavLink></li>
            <li><NavLink to="/gallery" className={() => `navlink`}>Gallery</NavLink></li>
            <li><NavLink to="/specials" className={() => `navlink`}>Specials</NavLink></li>
            <li><NavLink to="/reviews" className={() => `navlink`}>Reviews</NavLink></li>
            <li><NavLink to="/contact" className={() => `navlink`}>Contact Us</NavLink></li>
          </ul>
        </div>
        <div className="footerBox2">
          <h3>Opening Hours</h3>
          <div>
            <h4>Mon-Fri</h4>
            <h4>9:00 am - 10:00pm</h4>
          </div>
          <div>
            <h4>Sat-Sun</h4>
            <h4>10:00 am - 11:00pm</h4>
          </div>

        </div>

        <div className="footerBox4">
          <h3>Location</h3>
          <div className='mapBox'>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.519522181684!2d73.06941857520448!3d19.04088258215646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c347a4791f0d%3A0x4d4c50e8336ed8d3!2sPillai&#39;s%20Cafe%20Bir-yummy!5e0!3m2!1sen!2sin!4v1713426947220!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className="footerSubContainer">
        <h3>Made By Himali Ajay Chavan | All Rights Reserved 2024</h3>
      </div>
    </>
  )
}

export default Footer
