import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Components/Layout.jsx'
import Home from './Components/Home/Home.jsx'
import About from './Components/About/About.jsx'
import Menu from './Components/Menu/Menu.jsx';
import Gallery from './Components/Gallery/Gallery.jsx';
import OurBranches from './Components/OurBranches/OurBranches.jsx';
import Reviews from './Components/Reviews/Reviews.jsx';
import Contact from './Components/Contact/Contact.jsx';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/menu",
        element: <Menu />
      },
      {
        path: "/gallery",
        element: <Gallery />
      },
      {
        path: "/OurBranches",
        element: <OurBranches />
      },
      {
        path: "/reviews",
        element: <Reviews />
      },
      {
        path: "/contact",
        element: <Contact />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

