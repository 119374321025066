import React from 'react'
import './OurBranches.css'
// import Kamothe from '../../Images/tawaKamothe.jpg'
// import Panvel from '../../Images/tawaPanvel.jpg'
// import Kharghar from '../../Images/tawaBg7.jpg'
import Logo from '../../Images/tawaName.png'




function OurBranches() {
  return (
    <>
      <div className="headerBar">
        <h1>Our Branches</h1>
      </div>
      <div className="branchesContainer">
        <div className="branchesContainer1">
          <img src={Logo} alt="" />
          <h2>TAWA Kamothe</h2>
          <p>Experience Tawa's authentic flavors in Kamothe, where traditional recipes meet modern dining for a delightful culinary journey.</p>
          <p>+91 8424032647</p>
          <button>Get Location</button>
        </div>
        <div className="branchesContainer2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3480953185144!2d73.06240527520465!3d19.04842698215051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c21888bb8c5f%3A0x52800e5daa360e5c!2sTawa%20Multicuisine%20Restaurant!5e0!3m2!1sen!2sin!4v1714118575892!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='tawaK' referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="branchesContainer">
        <div className="branchesContainer1">
          <img src={Logo} alt="" />
          <h2>TAWA Kharghar</h2>
          <p>Tawa Kharghar offers a warm ambiance with signature dishes, perfect for family dining and savoring the essence of Indian cuisine.</p>
          <p>+91 8424032647</p>
          <button>Get Location</button>
        </div>
        <div className="branchesContainer2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3480953185144!2d73.06240527520465!3d19.04842698215051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c21888bb8c5f%3A0x52800e5daa360e5c!2sTawa%20Multicuisine%20Restaurant!5e0!3m2!1sen!2sin!4v1714118575892!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='tawaK' referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="branchesContainer">
        <div className="branchesContainer1">
          <img src={Logo} alt="" />
          <h2>TAWA Panvel</h2>
          <p>Discover Tawa's rich culinary heritage in Panvel, blending classic spices with contemporary tastes for a memorable dining experience.</p>
          <p>+91 8424032647</p>
          <button>Get Location</button>
        </div>
        <div className="branchesContainer2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3480953185144!2d73.06240527520465!3d19.04842698215051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c21888bb8c5f%3A0x52800e5daa360e5c!2sTawa%20Multicuisine%20Restaurant!5e0!3m2!1sen!2sin!4v1714118575892!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='tawaK' referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>







      {/* <div className="branchesContainer">
        <div className="branch">
          <img src={Kamothe} alt="" />
          <div className="branchInfo">
            <h2>Tawa Hotel Kamothe</h2>
            <p>Shop No 1/2/3/4/5, Plot No 53/54, Space Heights, Kamothe, Navi Mumbai - 410209</p>
            <button><Link className="links" to="https://maps.app.goo.gl/pVSB9piGTkkCM16V9" target='_blank'>Get Location</Link></button>
          </div>
        </div>
        <div className="branch">
          <img src={Panvel} alt="" />
          <div className="branchInfo">
            <h2>Tawa Hotel Panvel</h2>
            <p>Mcch Society 157 Opposite Dena Bank, Panvel 410206</p>
            <button><Link className="links" to="https://maps.app.goo.gl/pVSB9piGTkkCM16V9" target='_blank'>Get Location</Link></button>
          </div>
        </div>
        <div className="branch">
          <img src={Kharghar} alt="" />
          <div className="branchInfo">
            <h2>Tawa Hotel Kharghar</h2>
            <p>No 37 & 38, Hariom Heritage, opposite Kesar Harmony, Sector 21, Kharghar</p>
            <button><Link className="links" to="https://maps.app.goo.gl/tb1WcuByRY9FKJRi6" target='_blank'>Get Location</Link></button>
          </div>
        </div>
        
      </div> */}
    </>
  )
}

export default OurBranches
