import React from 'react'
import './About.css'
import foodBg from '../../Images/foodBg.jpg'

function About() {
  return (
    <>
      <div className="headerBar">
        <h1>About Us</h1>
      </div>
      <div className="aboutUsContainer">
        <div className="aboutUsContainer1">
          <h1>Our Story</h1>
        </div>
        <div className="aboutUsContainer2">
          <p>Tawa is a culinary landmark, offering an exquisite blend of traditional and contemporary Indian flavors in every dish. Our restaurant is a testament to the art of cooking, where each meal is prepared with the finest ingredients and an unwavering commitment to authenticity. With branches in Panvel, Kharghar, and other locations, Tawa is where food lovers gather to indulge in a dining experience that is both rich in taste and warm in hospitality.<br /><br />At Tawa, we bring to your table a celebration of flavors that embody the rich culinary heritage of India. Established with a passion for serving authentic and delicious meals, Tawa has become a beloved dining destination across Panvel, Kharghar, and beyond. Our commitment to quality, freshness, and taste is what sets us apart.

</p>
        </div>
      </div>
      <div className="aboutUsInfo">
        <div className="aboutUsInfo1">
          <div className="aboutUsInfoIcon">
            <i class="fa-brands fa-instagram"></i>
            <div className="aboutUsInfoContent">
              <h3>Exceptional Quality</h3>
              <p> Every dish is made using the freshest, finest ingredients available.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-brands fa-instagram"></i>
            <div className="aboutUsInfoContent">
              <h3>Authentic Flavors</h3>
              <p>Experience the true taste of Indian cuisine with every bite.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-brands fa-instagram"></i>
            <div className="aboutUsInfoContent">
              <h3>Warm Hospitality</h3>
              <p>Our staff ensures a welcoming and delightful dining experience always.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-brands fa-instagram"></i>
            <div className="aboutUsInfoContent">
              <h3>Diverse Menu</h3>
              <p>A wide variety of dishes to satisfy every palate and preference.
              </p>
            </div>
          </div>
        </div>
        <div className="aboutUsInfo2">
          <img src={foodBg} alt="" />
        </div>
      </div>
    </>
  )
}

export default About
