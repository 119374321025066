import React from 'react'
import TawaLogo from '../../Images/tawaName.png'
import AboutImg from '../../Images/aboutImg.jpg'
import './Home.css'
import Undi from '../../Images/undiOrange.png'
import { Link } from 'react-router-dom'


function Home() {
  return (
    <>
      <main>
        <div className="container">
          <div className="box1">
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-youtube"></i>
          </div>
          <div className="box2">
            <img className='logoImg' src={TawaLogo} alt="" />
            <img className='undi' src={Undi} alt="" />
            <h2>Multi Cuisine Restaurant</h2>
            <p>Experience the rich heritage of Indian cuisine with every bite, only at Tawa – where flavors and tradition meet.</p>
            <button>Know More</button>
          </div>
          <div className="box3">
            <button className='vertical-button'> <Link to="https://maps.app.goo.gl/z9bgWaywttZJckDh6" target='_blank'> Get Location</Link> </button>
            <img src={Undi} alt="" />
          </div>
        </div>
      </main >
      <div className="aboutContainer">
        <div className="aboutImg">
          <img src={AboutImg} alt="" />
        </div>
        <div className="aboutInfo">
          <h3>Tawa Mulricuisine Restaurant</h3>
          <img src={Undi} alt="" />
          <p>At Tawa, we bring to your table a celebration of flavors that embody the rich culinary heritage of India. Established with a passion for serving authentic and delicious meals, Tawa has become a beloved dining destination across Panvel, Kharghar, and beyond. Our commitment to quality, freshness, and taste is what sets us apart.</p>
          <p>From the sizzle of the Tawa to the aroma of our spices, every dish is crafted with love and care by our seasoned chefs. Whether you’re here for a casual family dinner, a special occasion, or a quick bite, Tawa promises an unforgettable experience that tantalizes your taste buds and warms your heart.</p>
          <button>KNOW MORE ABOUT US</button>
        </div>
      </div>
    </>
  )
}

export default Home
