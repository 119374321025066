import React from 'react'
import './Contact.css'
import Underline from '../../Images/underline.png'
import Logo from '../../Images/tawaLogo.png';

function Contact() {
  return (
    <>
      <div className="headerBar">
        <h1>Contact Us</h1>
      </div>

      <div className="contactContainer">
        <div className="formbox2">
          <img src={Logo} alt="" />
          
        </div>
        <div className="formbox1">
          <h5>CONNECT WITH US</h5>
          <h2>Send Us A Message</h2>
          <img src={Underline} alt="" />
          <div className="form">
            <form action="">
              <input type="text" placeholder='Your Name' />
              <input type="email" placeholder='Your Email' />
              <input type="tel" placeholder='Your Phone Number' />
              <input type="text" placeholder='Subject' />
              <textarea cols="30" rows="10" placeholder='Write A Message'></textarea>
              <button>Send Message</button>
            </form>
          </div>
        </div>

      </div>
      <div className="mapContainer">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.3480953185144!2d73.06240527520465!3d19.04842698215051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c21888bb8c5f%3A0x52800e5daa360e5c!2sTawa%20Multicuisine%20Restaurant!5e0!3m2!1sen!2sin!4v1714118575892!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='tawaK' referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  )
}

export default Contact
