import React, { useState } from 'react';
import Logo from '../../Images/tawaLogo.png';
import { Link, NavLink } from "react-router-dom";
import './Header.css';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="option">
                <div className="topHeader">
                    <ul>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-phone"></i>
                            <p>+91 9819370413</p>
                        </Link></li>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-envelope"></i>
                            <p>tawakharghar@gmail.com</p>
                        </Link></li>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>No 37 & 38, Hariom Heritage, opposite Kesar Harmony, Sector 21, Kharghar, Navi Mumbai, Maharashtra 410210</p>
                        </Link></li>
                    </ul>
                    <div className="socials">
                        <i className="fa-brands fa-instagram"></i>
                        <i className="fa-brands fa-facebook"></i>
                        <i className="fa-brands fa-youtube"></i>
                    </div>
                </div>
            </div>
            <div className={isMenuOpen ? "mobileMenu open" : "close"}>
                    <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
                    <li><NavLink to="/about" className="navlink" onClick={toggleMenu}>About Us</NavLink></li>
                    <li><NavLink to="/menu" className="navlink" onClick={toggleMenu}>Menu</NavLink></li>
                    <li><NavLink to="/gallery" className="navlink" onClick={toggleMenu}>Gallery</NavLink></li>
                    <li><NavLink to="/OurBranches" className="navlink" onClick={toggleMenu}>Our Branches</NavLink></li>
                    <li><NavLink to="/reviews" className="navlink" onClick={toggleMenu}>Reviews</NavLink></li>
                    <li><NavLink to="/contact" className="navlink" onClick={toggleMenu}>Contact Us</NavLink></li>
            </div>
            <header className="header">
                <div className="logo">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
                </div>
                <div className="navbar">
                    <ul>
                        <li><NavLink to="/" className="navlink" >Home</NavLink></li>
                        <li><NavLink to="/about" className="navlink" >About Us</NavLink></li>
                        <li><NavLink to="/menu" className="navlink" >Menu</NavLink></li>
                        <li><NavLink to="/gallery" className="navlink" >Gallery</NavLink></li>
                        <li><NavLink to="/OurBranches" className="navlink" >Our Branches</NavLink></li>
                        <li><NavLink to="/reviews" className="navlink" >Reviews</NavLink></li>
                        <li><NavLink to="/contact" className="navlink" >Contact Us</NavLink></li>
                    </ul>
                </div>
                <div className="book">
                    <button className='btn1'>Order Now</button>
                    <button>Book A Table</button>
                </div>
            </header>
        </>
    );
}

export default Header;
