import React from 'react'
import './Gallery.css'
import img1 from '../../Images/gridImages/milshake.jpg'
import img2 from '../../Images/gridImages/Img2.jpg'
import img3 from '../../Images/gridImages/Img3.jpg'
import img4 from '../../Images/gridImages/Img4.jpg'
import img5 from '../../Images/gridImages/Img5.jpg'
import img6 from '../../Images/gridImages/juice.jpg'
import img7 from '../../Images/gridImages/Img7.jpg'
import img8 from '../../Images/gridImages/Img8.jpg'



function Gallery() {
  return (
    <>
      <div className="headerBar">
        <h1>Gallery</h1>
      </div>
      <div className="galleryContainer">
        <div className="galleryBox" id="gbox-1">
          <img src={img1} alt="" />
        </div>
        <div className="galleryBox" id="gbox-2">
          <img src={img2} alt="" />
        </div>
        <div className="galleryBox" id="gbox-3">
          <img src={img3} alt="" />
        </div>
        <div className="galleryBox" id="gbox-4">
          <img src={img4} alt="" />
        </div>
        <div className="galleryBox" id="gbox-5">
          <img src={img5} alt="" />
        </div>
        <div className="galleryBox" id="gbox-6">
          <img src={img6} alt="" />
        </div>
        <div className="galleryBox" id="gbox-7">
          <img src={img7} alt="" />
        </div>
        <div className="galleryBox" id="gbox-8">
          <img src={img8} alt="" />
        </div>

      </div>
    </>
  )
}

export default Gallery
