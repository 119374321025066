import React from 'react'
import { useState } from 'react'
import './Menu.css'
// import undi from '../../Images/underline.png'
import Img1 from '../../Images/Img1.jpeg'
import Img2 from '../../Images/Img2.jpeg'
import Img3 from '../../Images/Img3.jpeg'
import Img4 from '../../Images/Img4.jpeg'
import Img5 from '../../Images/Img5.jpeg'
// import Img7 from '../../Images/Img7.jpeg'
// import Img8 from '../../Images/Img8.jpeg'
// import Img9 from '../../Images/Img9.jpeg'
// import Img10 from '../../Images/Img10.jpeg'
// import Img6 from '../../Images/Img6.jpeg'
// import Img12 from '../../Images/Img12.jpeg'
// import Img13 from '../../Images/Img13.jpeg'

import RiceImg from '../../Images/riceWh.jpg'
import SoupImg from '../../Images/soupW.jpg'
import VegImg from '../../Images/paneer.jpg'
import NonVegImg from '../../Images/chicken.jpg'



function Rice() {
  return (
    <>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Jeera Rice</h3>
          <p>Aromatic basmati rice.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 120</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Veg Pulao</h3>
          <p>Mixed vegetables basmati.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 150</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img2} alt="" />
        <div className="menuInfo">
          <h3> Chicken Rice </h3>
          <p>Tender chicken in spiced rice.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 250</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img3} alt="" />
        <div className="menuInfo">
          <h3>Egg Fried</h3>
          <p>Fluffy rice with scrambled eggs.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 180</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img4} alt="" />
        <div className="menuInfo">
          <h3>Lemon Rice</h3>
          <p>Zesty rice with fresh lemons.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 130</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img5} alt="" />
        <div className="menuInfo">
          <h3>Mushroom Biryani</h3>
          <p>Earthy mushrooms in spiced rice.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 220</h3>
        </div>
      </div>
    </>
  )
}


function Soup() {
  return (
    <>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Tomato Basil</h3>
          <p>Creamy tomato soup with basil.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 110</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Chicken Clear</h3>
          <p>Light broth with tender chicken.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 130</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img2} alt="" />
        <div className="menuInfo">
          <h3>Veg Manchow</h3>
          <p>Spicy vegetable soup with crunch.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 120</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img3} alt="" />
        <div className="menuInfo">
          <h3>Sweet Corn</h3>
          <p>Creamy corn with delicate flavor</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 100</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img4} alt="" />
        <div className="menuInfo">
          <h3>Hot Sour</h3>
          <p>Tangy soup with spicy kick.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 130</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img5} alt="" />
        <div className="menuInfo">
          <h3>Mushroom Soup</h3>
          <p>Creamy soup with wild mushrooms.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 140</h3>
        </div>
      </div>
    </>
  )
}


function Veg() {
  return (
    <>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Paneer Butter</h3>
          <p>Creamy paneer in rich gravy.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 220</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Aloo Gobi</h3>
          <p>Potatoes and cauliflower spiced perfectly.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 180</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img2} alt="" />
        <div className="menuInfo">
          <h3>Bhindi Masala</h3>
          <p>Spiced okra with onions, tomatoes.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 160</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img3} alt="" />
        <div className="menuInfo">
          <h3>Methi Malai</h3>
          <p>Creamy fenugreek leaves with spices.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 190</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img4} alt="" />
        <div className="menuInfo">
          <h3>Chole Bhature</h3>
          <p>Chickpea curry with fluffy bread.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 200</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img5} alt="" />
        <div className="menuInfo">
          <h3>Dal Tadka</h3>
          <p>Yellow lentils tempered with spices.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs 140</h3>
        </div>
      </div>
    </>
  )
}


function NonVeg() {
  return (
    <>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Butter Chicken</h3>
          <p>Creamy gravy with chicken.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 280</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img1} alt="" />
        <div className="menuInfo">
          <h3>Fish Curry</h3>
          <p>Tangy, spiced curry with fish.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 250</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img2} alt="" />
        <div className="menuInfo">
          <h3>Mutton Rogan</h3>
          <p>Spiced mutton in rich gravy.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 320</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img3} alt="" />
        <div className="menuInfo">
          <h3>Egg Curry</h3>
          <p>Boiled eggs in spicy gravy.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 180</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img4} alt="" />
        <div className="menuInfo">
          <h3>Chicken Korma</h3>
          <p>Rich, creamy chicken with nuts.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 270</h3>
        </div>
      </div>
      <div className="menu">
        <img src={Img5} alt="" />
        <div className="menuInfo">
          <h3>Prawn Masala</h3>
          <p>Succulent prawns in spicy gravy.</p>
          <div className="reviews">
            <p>4.5</p>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <p>(548 Reviews)</p>
          </div>
          <h3>Rs. 300</h3>
        </div>
      </div>
    </>
  )
}




function Menu() {
  const [activeComponent, setActiveComponent] = useState('Rice');

  const showComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <>
      <div className="headerBar">
        <h1>Menu</h1>
      </div>
      <div className="tabs">
        <div className="tabsDiv">
          <div id="innerTabDiv" className={activeComponent === 'Rice' ? 'button active' : 'button'} onClick={() => showComponent('Rice')}>
            <img src={RiceImg} alt="" />
            <h2>Rice</h2>
          </div>
          <div id="innerTabDiv" className={activeComponent === 'Soup' ? 'button active' : 'button'} onClick={() => showComponent('Soup')}>
            <img src={SoupImg} alt="" />
            <h2>Soup</h2>
          </div>
          <div id="innerTabDiv" className={activeComponent === 'Veg' ? 'button active' : 'button'} onClick={() => showComponent('Veg')}>
            <img src={VegImg} alt="" />
            <h2>Veg</h2>
          </div>
          <div id="innerTabDiv" className={activeComponent === 'NonVeg' ? 'button active' : 'button'} onClick={() => showComponent('NonVeg')}>
            <img src={NonVegImg} alt="" />
            <h2>Non Veg</h2>
          </div>

        </div>
      </div>
      <div className="menuContainer">
        {activeComponent === 'Rice' && <Rice />}
        {activeComponent === 'Soup' && <Soup />}
        {activeComponent === 'Veg' && <Veg />}
        {activeComponent === 'NonVeg' && <NonVeg />}
      </div>
    </>
  )
}

export default Menu
